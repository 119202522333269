
.help-wrapper {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.main-wrapper {
    padding: 35px 20px 28px;
    .header {
        border-bottom: 1px solid #EEEEEE;
        padding: 0 127px 20px;
        .title {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333;
            margin-right: 20px;
        }
        .time {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666;
        }
    }
    .help-content {
        padding: 20px 127px 20px;
    }
}
